import React from 'react';
import './Projects.css';
import Project from '../Project';

// Cover Photos
import PNYCover from '../../assets/images/commercial/PNY/PNY-4.jpg';
import BloomCover from '../../assets/images/newbuild/bloomingdale/Bloom-2.jpg';
import Home7Cover from '../../assets/images/newbuild/home7/Home7-1.jpeg';
import Home6Cover from '../../assets/images/newbuild/home6/Home6-1.jpeg';
import RenoCover from '../../assets/images/newbuild/bloomingdale/Bloom-1.jpg';


// Catalog Photos
import bloomData from './Data/Newbuild/bloomingdale.js'
import home7 from './Data/Newbuild/home7.js'
import pnyData from './Data/Commercial/pny.js';


const Projects = () => {
  // original data example
  // {
  //   "id": 1,
  //   "name": "Bloomingdale",
  //   "type": "NewBuild",
  //   "image": BloomCover,
  //   "detail": "4 beds, 3.5 baths, 3200 sq. ft",
  //   "catalog": bloomData
  // },
  const projectsNewCover = [
    {
      "id": 1,
      "name": "Wayne, PA",
      "type": "NewBuild",
      "image": BloomCover,
      // "detail": "4 beds, 3.5 baths, 3200 sq. ft",
      "catalog": bloomData
    },
    {
      "id": 2,
      "name": "Avalon, NJ",
      "type": "NewBuild",
      "image": Home7Cover,
      // "detail": "6 beds, 4 baths, 4500 sq. ft",
      "catalog": home7
    },
    {
      "id": 3,
      "name": "Stone Harbor, NJ",
      "type": "NewBuild",
      "image": Home6Cover,
      // "detail": "8 beds, 5 baths, 5000 sq. ft"
    },
  ]
  const projectsRenoCover = [
    {
      "id": 1,
      "name": "Wayne, PA",
      "type": "Renovation",
      "image": RenoCover,
      // "detail": "Kitchen & Bath"
    },
  ]
  const projectsCommCover = [
    {
      "id": 1,
      "name": "PNY Sports Arena",
      "type": "Commercial",
      "image": PNYCover,
      // "detail": "2 ice rinks, 1 bar, 18000 sq. ft",
      "catalog": pnyData
    },
  ]

  return (
    <div className="projects-container">
      <div id="NewBuild" className="projects-hd">New Properties</div>
      <div className="projects">
        {projectsNewCover.map(projCover => {
          if (projCover.type === 'NewBuild') {
            return (
              <>
                <Project projectInfo={projCover} />
              </>
            )
          }
          return (
            <div className="project">
              <div className="">Coming Soon...</div>
            </div>
          )
        })}
      </div>
      <div id="Renovation" className="projects-hd">Renovations</div>
      <div className="projects">
        {projectsRenoCover.map(projCover => {
          if (projCover.type === 'Renovation') {
            return (
              <>
                <Project projectInfo={projCover} />
              </>
            )
          }
          return (
            <div className="project">
              <div className="">Coming Soon...</div>
            </div>
          )
        })}
      </div>
      <div id="Commercial" className="projects-hd">Commercial Properties</div>
      <div className="projects">
        {projectsCommCover.map(projCover => {
          if (projCover.type === 'Commercial') {
            return (
              <>
                <Project projectInfo={projCover} />
              </>
            )
          }
          return (
            <div className="project">
              <div className="">Coming Soon...</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Projects;
