import React, { useState } from 'react';
import './Project.css';

import Modal from 'react-modal';
import Catalog from '../Catalog';

Modal.setAppElement('#root');


const Project = (props) => {
  const { projectInfo } = props;

  // Modal State
  const [modalIsOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  }

  const afterOpenModal = () => {
    // subtitle.style.color = '#f00';
  }

  const closeModal = () => {
    setIsOpen(false);
  }

  return (
    <>
      <div onClick={openModal} id={projectInfo.name} className="project">
        <img alt="random" className="project-cover" src={projectInfo.image} />

        <div className="image-overlay">
          <div className="overlay-container">
            <div><strong>{projectInfo.name}</strong></div>
            <br></br>
            <div>{projectInfo.detail}</div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <Catalog catalog={projectInfo.catalog} />
      </Modal>
    </>
  )
}

export default Project;