import React from 'react';
import './Footer.css';

import { HashLink } from 'react-router-hash-link';

const Footer = () => {



  return (
    <div className="footer-container">

      <div className="footer-inner">
        <div className="footer-left">
          <h2><strong>ZRM Builders</strong></h2>

          <div className="footer-co-name">
            ZRM Builders LLC
          </div>
          <div className="address">2778 Dune Drive Avalon, NJ 08202</div>

        </div>


        <div className="about-us"><h2>About Us</h2>
          <HashLink to="/about#ourteam">Our Team</HashLink>
          <HashLink 
            to="/projects#NewBuild"
            scroll={(el) => el.scrollIntoView({ behavior: 'instant', block: 'center' })}
          >
            Our Projects
          </HashLink>
        </div>
        
        <div className="contact-us"><h2>Contact Us</h2>
          <div>609-961-3781</div>
          <div>rranalli@zrmbuilders.com</div>
        </div>


      </div>
      <div className="footer-copyright">Copyright <span>&#169;</span> 2021 ZRM Builders LLC</div>
    </div>
  )

}

export default Footer;