import React from 'react';
import './About.css';

import Avalon from '../../assets/images/location/avalonpier.jpeg';
import Wayne from '../../assets/images/location/wayne.jpeg';
import Malvern from '../../assets/images/location/malvern.jpeg';


const About = (props) => {

  console.log(Avalon);
  const Team = [
    {
      "member": "Frank Zeigler",
      "role": "Partner"
    },
    {
      "member": "Rich Ranalli",
      "role": "Partner"
    }
    
  ]

  const Locations = [
    {
      "location": "Avalon, New Jersey",
      "image": Avalon,
    },
    {
      "location": "Wayne, Pennsylvania",
      "image": Wayne,
    },
    {
      "location": "Malvern, Pennsylvania",
      "image": Malvern,
    }
  ]

  console.log(Locations);

  return (
    <div className="about-container">
      <div className="about-top-left">
        <div className="about-headline">About Us</div>

        <p>
          For 25 years ZRM has built custom luxury homes along the Southern New Jersey Shoreline
          and in the prominent suburbs of Philadelphia.
        </p>
      </div>

      <div className="about-headline">Locations</div>
      <div className="location-container">
        {Locations.map(loc => {
          return (
            <>
              <div className="loc-overlay">
                <div className="loc-header">{loc.location}</div>
                <img alt="random" className="location-img" src={loc.image} />
              </div>
            </>
          )
        })}
      </div>

      <div className="about-headline">Our Team</div>
      <div id="ourteam" className="team-container">
        {Team.map(t => {
          return (
            <>
              <div className="member-card">
                <div className="member-name">{t.member}</div>
                <div className="member-role">{t.role}</div>
              </div>
            </>
          )
        })}

      </div>

    </div>
  )

}

export default About;