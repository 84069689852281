import React from 'react';
import './Contact.css';
import { FiInstagram } from 'react-icons/fi';


const Contact = () => {

  return (
    <div className="contact-container">
      <div className="contact-header">
        <div className="contact-text">Contact Us</div>
      </div>

      <div className="contact-bottom">
        <div className="form-container">
          <div>ZRM Builders</div>
          <a className="insta-icon" target="_blank" href="https://www.instagram.com/zrm_builders/" >
          <FiInstagram size={'50'} color={'#294974e8'}/>
          </a>
        </div>
        <div className="map-container"></div>
      </div>


    </div>
  )

}

export default Contact;
