import bloom1 from '../../../../assets/images/newbuild/bloomingdale/Bloom-1.jpg';
import bloom2 from '../../../../assets/images/newbuild/bloomingdale/Bloom-2.jpg';
import bloom3 from '../../../../assets/images/newbuild/bloomingdale/Bloom-3.jpg';
import bloom4 from '../../../../assets/images/newbuild/bloomingdale/Bloom-4.jpg';

import bloom6 from '../../../../assets/images/newbuild/bloomingdale/Bloom-6.jpg';
import bloom7 from '../../../../assets/images/newbuild/bloomingdale/Bloom-7.jpg';
import bloom8 from '../../../../assets/images/newbuild/bloomingdale/Bloom-8.jpg';
import bloom9 from '../../../../assets/images/newbuild/bloomingdale/Bloom-9.jpg';
import bloom10 from '../../../../assets/images/newbuild/bloomingdale/Bloom-10.jpg';
import bloom11 from '../../../../assets/images/newbuild/bloomingdale/Bloom-11.jpg';
import bloom12 from '../../../../assets/images/newbuild/bloomingdale/Bloom-12.jpg';
import bloom13 from '../../../../assets/images/newbuild/bloomingdale/Bloom-13.jpg';
import bloom14 from '../../../../assets/images/newbuild/bloomingdale/Bloom-14.jpg';
import bloom15 from '../../../../assets/images/newbuild/bloomingdale/Bloom-15.jpg';

import bloom17 from '../../../../assets/images/newbuild/bloomingdale/Bloom-17.jpg';

import bloom20 from '../../../../assets/images/newbuild/bloomingdale/Bloom-20.jpg';

import bloom24 from '../../../../assets/images/newbuild/bloomingdale/Bloom-24.jpg';
import bloom25 from '../../../../assets/images/newbuild/bloomingdale/Bloom-25.jpg';
import bloom26 from '../../../../assets/images/newbuild/bloomingdale/Bloom-26.jpg';
import bloom27 from '../../../../assets/images/newbuild/bloomingdale/Bloom-27.jpg';
import bloom28 from '../../../../assets/images/newbuild/bloomingdale/Bloom-28.jpg';
import bloom29 from '../../../../assets/images/newbuild/bloomingdale/Bloom-29.jpg';
import bloom30 from '../../../../assets/images/newbuild/bloomingdale/Bloom-30.jpg';
import bloom31 from '../../../../assets/images/newbuild/bloomingdale/Bloom-31.jpg';


const bloomData = [
  { 
    "id": 1, 
    "image": bloom1
  },
  { 
    "id": 2, 
    "image": bloom2
  },
  { 
    "id": 3, 
    "image": bloom3
  },
  { 
    "id": 4, 
    "image": bloom4
  },
  { 
    "id": 6, 
    "image": bloom6
  },
  { 
    "id": 7, 
    "image": bloom7
  },
  { 
    "id": 8, 
    "image": bloom8
  },
  { 
    "id": 9, 
    "image": bloom9
  },
  { 
    "id": 10, 
    "image": bloom10
  },
  { 
    "id": 11, 
    "image": bloom11
  },
  { 
    "id": 12, 
    "image": bloom12
  },
  { 
    "id": 13, 
    "image": bloom13
  },
  { 
    "id": 14, 
    "image": bloom14
  },
  { 
    "id": 15, 
    "image": bloom15
  },
  { 
    "id": 17, 
    "image": bloom17
  },
  { 
    "id": 20, 
    "image": bloom20
  },
  { 
    "id": 24, 
    "image": bloom24
  },
  { 
    "id": 25, 
    "image": bloom25
  },
  { 
    "id": 26, 
    "image": bloom26
  },
  { 
    "id": 27, 
    "image": bloom27
  },
  { 
    "id": 28, 
    "image": bloom28
  },
  { 
    "id": 29, 
    "image": bloom29
  },
  { 
    "id": 30, 
    "image": bloom30
  },
  { 
    "id": 31, 
    "image": bloom31
  },
]

export default bloomData;

