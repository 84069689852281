import pny1 from '../../../../assets/images/commercial/PNY/PNY-1.jpg';
import pny3 from '../../../../assets/images/commercial/PNY/PNY-3.jpg';

import pny4 from '../../../../assets/images/commercial/PNY/PNY-4.jpg';
import pny6 from '../../../../assets/images/commercial/PNY/PNY-6.jpg';

import pny7 from '../../../../assets/images/commercial/PNY/PNY-7.jpg';
import pny8 from '../../../../assets/images/commercial/PNY/PNY-8.jpg';
import pny9 from '../../../../assets/images/commercial/PNY/PNY-9.jpg';
import pny10 from '../../../../assets/images/commercial/PNY/PNY-10.jpg';
import pny12 from '../../../../assets/images/commercial/PNY/PNY-12.jpg';

import pny13 from '../../../../assets/images/commercial/PNY/PNY-13.jpg';
import pny14 from '../../../../assets/images/commercial/PNY/PNY-14.jpg';

import pny16 from '../../../../assets/images/commercial/PNY/PNY-16.jpg';
import pny17 from '../../../../assets/images/commercial/PNY/PNY-17.jpg';

import pny19 from '../../../../assets/images/commercial/PNY/PNY-19.jpg';

import pny21 from '../../../../assets/images/commercial/PNY/PNY-21.jpg';
import pny22 from '../../../../assets/images/commercial/PNY/PNY-22.jpg';
import pny23 from '../../../../assets/images/commercial/PNY/PNY-23.jpg';

const pnyData = [
  {
    "id": 1,
    "image": pny1
  },
  {
    "id": 3,
    "image": pny3
  },
  {
    "id": 4,
    "image": pny4
  },
  {
    "id": 6,
    "image": pny6
  },
  {
    "id": 7,
    "image": pny7
  },
  {
    "id": 8,
    "image": pny8
  },
  {
    "id": 9,
    "image": pny9
  },
  {
    "id": 10,
    "image": pny10
  },
  {
    "id": 12,
    "image": pny12
  },
  {
    "id": 13,
    "image": pny13
  },
  {
    "id": 14,
    "image": pny14
  },
  {
    "id": 16,
    "image": pny16
  },
  {
    "id": 17,
    "image": pny17
  },
  {
    "id": 19,
    "image": pny19
  },
  {
    "id": 21,
    "image": pny21
  },
  {
    "id": 22,
    "image": pny22
  },
  {
    "id": 23,
    "image": pny23
  }
]

export default pnyData;