import home71 from '../../../../assets/images/newbuild/home7/Home7-1.jpeg';
import home72 from '../../../../assets/images/newbuild/home7/Home7-2.jpeg';
import home73 from '../../../../assets/images/newbuild/home7/Home7-3.jpeg';
import home74 from '../../../../assets/images/newbuild/home7/Home7-4.jpeg';
import home75 from '../../../../assets/images/newbuild/home7/Home7-5.jpeg';
import home76 from '../../../../assets/images/newbuild/home7/Home7-6.jpeg';

import home78 from '../../../../assets/images/newbuild/home7/Home7-8.jpeg';
import home79 from '../../../../assets/images/newbuild/home7/Home7-9.jpeg';
import home710 from '../../../../assets/images/newbuild/home7/Home7-10.jpeg';
import home711 from '../../../../assets/images/newbuild/home7/Home7-11.jpeg';
import home712 from '../../../../assets/images/newbuild/home7/Home7-12.jpeg';

const Home7 = [
  {
    "id": 1,
    "image": home71
  },
  {
    "id": 2,
    "image": home72
  },
  {
    "id": 3,
    "image": home73
  },
  {
    "id": 4,
    "image": home74
  },
  {
    "id": 5,
    "image": home75
  },
  {
    "id": 6,
    "image": home76
  },
  {
    "id": 8,
    "image": home78
  },
  {
    "id": 9,
    "image": home79
  },
  {
    "id": 10,
    "image": home710
  },
  {
    "id": 11,
    "image": home711
  },
  {
    "id": 12,
    "image": home712
  },

]

export default Home7;