import React, { useState, useEffect } from 'react';
import './Splash.css';
import Home from '../Home';
import { Switch, Route, Redirect } from 'react-router-dom';

const Splash = (props) => {
  
  const [currentPath, setCurrentPath] = useState("/")

  useEffect(
    () => {
      const timer = setTimeout(() => {

        setCurrentPath("/home");

        // change the url to "home";
        props.history.push("/home");

      }, 4000);

      // this will clear Timeout when component unmount
      return () => {

        clearTimeout(timer);

      }
    },
    [] //useEffect will run only one time
  )





  return (
    <>
      { currentPath == "/" ?

        <div className="splash-container">

          <div className="main-splash"></div>


          <div className="jumbo-section">
            <div className="jumbo-header">Welcome to<strong>

              <span className="jumbo-letter-z"> Z</span>
              <span className="jumbo-letter-r">R</span>
              <span className="jumbo-letter-m">M </span>


            </strong> builders.

            </div>

          </div>

        </div>

        : 

        <Home />
      }
    </>

  )
};


export default Splash;