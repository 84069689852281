import React from 'react';
import './Catalog.css';

const Catalog = (props) => {

  const { catalog } = props;

  console.log(catalog);

  return (
    <div className="catalog-container">
      {catalog !== undefined ?
        <>
          {catalog.map(cat => {
            if (cat.image !== undefined) {
              return (
                <>
                  <div className="modal-pictures">
                    <img alt="random" className="catalog-image" src={cat.image} />
                  </div>
                </>
              )
            }
          })}
        </>
        :
        <>
          <div className="modal-pictures">
            <h2>Photos coming soon...</h2>
          </div>
        </>
      }

    </div>
  )
}

export default Catalog;