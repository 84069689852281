import React from 'react';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Splash from './components/Splash';

function App(props) {

  return (
    <div className="App">
      
      <Header />

      { props.location.pathname == "/" ? <Splash path={props.location.pathname} history={props.history} /> : null }

      <Footer />
    
    </div>
  );
}

export default App;
