import React from 'react';
import './Home.css';
import { HashLink } from 'react-router-hash-link';

const Home = (props) => {

  return (
    <div className="home-container">
      <div className="home-top-view">
        <div className="home-tag-line">High Quality Residential and Commercial Construction</div>
      </div>
      <div className="home-bottom-view">
        <div className="home-box-one">
          <HashLink 
            className="box-one-btn" 
            to="/projects#NewBuild"
            scroll={(el) => el.scrollIntoView({ behavior: 'instant', block: 'center' })}
          >
              New Build
          </HashLink>
        </div>
        <div className="home-box-two">
          <HashLink 
            className="box-two-btn" 
            to="/projects#Renovation"
            scroll={(el) => el.scrollIntoView({ behavior: 'instant', block: 'center' })}
            >
              Renovation
          </HashLink>
        </div>
        <div className="home-box-three">
          <HashLink 
            className="box-three-btn" 
            to="/projects#Commercial"
            scroll={(el) => el.scrollIntoView({ behavior: 'instant', block: 'center' })}
          >
            Commercial
          </HashLink>
        </div>
      </div>
    </div>
  )
}

export default Home;
