import React, { useState } from 'react';
import './Header.css';
import { Switch, Route, NavLink } from 'react-router-dom';
import Home from '../Home';
import Projects from '../Projects';
import About from '../About';
import Contact from '../Contact';
import { FiMenu } from "react-icons/fi";
import mainlogo from '../../assets/images/logo4.svg';
import Modal from 'react-modal';

const Header = () => {

  // modal menu implementation
  const [headModalIsOpen, setHeadModalIsOpen] = useState(false);
  const openHeadModal = () => {
    setHeadModalIsOpen(true);
  };
  const afterOpenHeadModal = () => {
    // return null;
    // setHeadModalIsOpen(false);
  } 
  const closeHeadModal = () => {
    setHeadModalIsOpen(false);
  }


  let navLinks = ['Home', 'Projects', 'About', 'Contact'];

  return (
    <div>
      <div className="header">
        <div className="name-container">
          <div className="company-name">
            <img className="mainlogoimg" src={mainlogo} alt="main-logo" />
          </div>
          <div className="company-name-sub">
          </div>
        </div>

        <nav className="header-right-nav">
          {navLinks.map(aLink => {
            return (
              <div key={aLink}>
                <NavLink
                  className="nav-item"
                  id={aLink.toLowerCase()}
                  to={`/${aLink.toLowerCase()}`}
                  exact={true}
                  activeClassName="selected"
                >
                  {aLink}
                </NavLink>
              </div>
            )
          })}

        </nav>

        <FiMenu className="icon" onClick={openHeadModal} />

      </div>

      <div className="content">
        <Switch>
          <Route path="/home" component={Home} />
          <Route path="/projects" component={Projects} />
          <Route path="/about" component={About} />
          <Route path="/contact" component={Contact} />

        </Switch>
      </div>


      <Modal
        isOpen={headModalIsOpen}
        onAfterOpen={afterOpenHeadModal}
        onRequestClose={closeHeadModal}
        contentLabel="Example Modal"
        id="menuModal"
      >
        <div className="headInnerModal">
          <NavLink onClick={closeHeadModal} to="/home">Home</NavLink>
          <NavLink onClick={closeHeadModal} to="/projects">Projects</NavLink>
          <NavLink onClick={closeHeadModal} to="/about">About</NavLink>
          <NavLink onClick={closeHeadModal} to="/contact">Contact</NavLink>

        </div>
      </Modal>


    </div>
  )

};

export default Header;